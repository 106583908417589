import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type {
  IBackofficeHotlineChatRoom,
  IBackofficeHotlineListResponse,
  IBackofficeHotlinePage,
  IChatRoomCasenoteFormResponse,
  IHotlineCasenote,
  IHotlineComment,
  IHotlineTransferCasenote,
  IQueuePage,
} from "domains/interfaces/backoffice/hotline"
import type { IForm, IFormResponse, IFormResponseAnswer } from "domains/interfaces/form"
import type { IHotlineChatRoom } from "domains/interfaces/hotline"
import { inject, injectable } from "inversify"
import type { IApiService } from "./apiService"
import { TYPES } from "./types"

export interface IBackofficeHotlineService {
  getChatRoom(chatRoomId: number): Promise<IBackofficeHotlineChatRoom | null>
  getChatRoomCasenoteForm(): Promise<{ form: IForm }>
  joinChatRoom(chatRoomId: number): Promise<IHotlineChatRoom>
  list(filter?: any): Promise<IBackofficeHotlinePage>
  submitChatRoomCasenote(
    chatRoomId: number,
    answer: { answers: IFormResponseAnswer[] }
  ): Promise<IFormResponse>
  getCases(view: string, filter?: any): Promise<IQueuePage>
  editAssignedUsername(lineUserId: string, newAssignedUsername: string): Promise<void>
  deleteCaseNote(id: number, reasonId: number, reason: string): Promise<void>
  getDeleteReason(status_key?: string): Promise<any>
  assignCase(
    id: string,
    counselorId: number,
    customDisplayName?: string,
    sendFeecback?: boolean
  ): Promise<any>
  createNewCase(
    dateTime: string,
    conselorId: number,
    firstName: string,
    lastName: string,
    customerUserName: string,
    newAssignedUsername?: string,
    lineUserName?: string,
    message_first_timestamp?: string,
    message_last_timestamp?: string,
    reason?: string
  ): Promise<any>
  submitCasenote(id: string, data: IHotlineCasenote): Promise<any>
  saveCasenoteDraft(id: string, data: IHotlineCasenote | {}): Promise<any>
  sendFeedbackForm(hotlineId: string): Promise<any>
  sendComment(id: string, data: IHotlineComment): Promise<any>
  getCaseInfo(id: string): Promise<any>
  getCommentForm(id: string): Promise<any>
  submitTransferCasenote(id: string, data: IHotlineTransferCasenote): Promise<any>
  getTransferCasenote(id: string | number): Promise<any>
  setFollowUpStatus(id: string | number, data: string): Promise<any>
  getFolloUpStatus(id: string | number): Promise<any>
}

@injectable()
export class BackofficeHotlineService implements IBackofficeHotlineService {
  private apiService: IApiService

  constructor(@inject(TYPES.IApiService) apiService: IApiService) {
    this.apiService = apiService
  }

  public async getChatRoom(chatRoomId: number): Promise<IBackofficeHotlineChatRoom | null> {
    const response = await this.apiService.get<
      IBaseResponse<{
        chat_room: IBackofficeHotlineChatRoom
      }>
    >(`backoffice/hotline/chat-rooms/${chatRoomId}`)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      return null
    }
    return response.data.data.chat_room
  }

  public async getChatRoomCasenoteForm(): Promise<{ form: IForm }> {
    const response = await this.apiService.get<IChatRoomCasenoteFormResponse>(
      `backoffice/hotline/chat-rooms/casenote/form`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    let formatData: any = response.data.data
    return formatData!
  }

  public async joinChatRoom(chatRoomId: number): Promise<IHotlineChatRoom> {
    const response = await this.apiService.get<IBaseResponse<{ chat_room: IHotlineChatRoom }>>(
      `hotline/chat-rooms/${chatRoomId}/join`
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.chat_room
  }

  public async list(filter: any = { page: 1, size: 10 }): Promise<IBackofficeHotlinePage> {
    const response = await this.apiService.get<IBackofficeHotlineListResponse>(
      "backoffice/hotline/chat-rooms",
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async submitChatRoomCasenote(
    chatRoomId: number,
    answer: { answers: IFormResponseAnswer[] }
  ): Promise<IFormResponse> {
    const response = await this.apiService.post<IBaseResponse<{ casenote: IFormResponse }>>(
      `backoffice/hotline/chat-rooms/${chatRoomId}/casenote`,
      answer,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.casenote
  }

  public async getCases(view: string, filter: any = { page: 1, size: 10 }): Promise<IQueuePage> {
    const response = await this.apiService.get<any>(
      `backoffice/hotline/list?sortBy=created_at&sortOrder=desc&view=${view}`,
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async getDeleteReason(status_key?: string): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/hotline/status-reason?status_key=${status_key || "canceled"}`,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async editAssignedUsername(
    newAssignedUsername: string,
    lineUserId: string
  ): Promise<void> {
    const response = await this.apiService.post<void>(
      `backoffice/hotline/line/custom-displayname`,
      {
        custom_display_name: `${newAssignedUsername}`,
        line_user_id: `${lineUserId}`,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async deleteCaseNote(id: number, reasonId: number, reason: string): Promise<void> {
    if (!reasonId || typeof reasonId !== "number") {
      throw new Error("Validation failed: reasonId is required and must be a number.")
    }
    if (reason && typeof reason !== "string") {
      throw new Error("Validation failed: reason must be a string.")
    }
    const payload = {
      reason_id: reasonId,
      reason: reason,
    }
    const response = await this.apiService.delete<void>(`backoffice/hotline/${id}`, payload, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async assignCase(
    id: string,
    counselorId: number,
    customDisplayName?: string,
    sendFeecback?: boolean
  ): Promise<any> {
    const response = await this.apiService.post<void>(
      `backoffice/hotline/${id}/assign`,
      {
        counselor_id: counselorId,
        custom_display_name: customDisplayName,
        send_feedback: sendFeecback,
      },
      true
    )

    if (sendFeecback) {
      this.sendFeedbackForm(id)
    }
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async createNewCase(
    dateTime: string,
    conselorId: number,
    firstName: string,
    lastName: string,
    customerUserName: string,
    newAssignedUsername?: string,
    lineUserName?: string,
    message_first_timestamp?: string,
    message_last_timestamp?: string,
    reason?: string
  ): Promise<any> {
    const response = await this.apiService.post<any>(
      `backoffice/hotline/create`,
      {
        datetime: dateTime,
        counselor_id: conselorId,
        message_first_timestamp: message_first_timestamp,
        message_last_timestamp: message_last_timestamp,
        customer_firstname: firstName,
        customer_lastname: lastName,
        customer_username: customerUserName,
        line_display_name: lineUserName,
        custom_display_name: newAssignedUsername,
        reason: reason,
      },
      true
    )
    console.log(response)
    if (response.status !== ApiResponseStatus.Ok) {
      console.log(response)
      throw new Error(response.status + "")
    }
    return response.data.data
  }

  public async submitCasenote(id: string, data: IHotlineCasenote): Promise<any> {
    const response = await this.apiService.post<any>(
      `backoffice/hotline/${id}/casenote/submit`,
      {
        lead_problem: data.leadProblem.trim(),
        general_info: data.generalInfo.trim(),
        problem_detail: data.problemDetail.trim(),
        help_method: data.helpMethod.trim(),
        result: data.result.trim(),
        refer_counselor_id: data.headShiftCounselorId ?? -1,
        remark: data.remark,
        suicidal_assessment: data.suicidalAssessment ?? null,
        suicidal_idea: data.suicidalIdea ?? null,
        suicidal_frequent: data.suicidalFrequent ?? null,
        suicidal_plan: data.suicidalPlan ?? null,
        suicidal_history_assessment: data.suicidalHistoryAssessment ?? null,
        suicidal_attempt: data.suicidalAttempt ?? null,
        selfharm_assessment: data.selfharmAssessment ?? null,
        selfharm_idea: data.selfharmIdea ?? null,
        suicidal_plan_text: data.suicidalPlanText,
        safety_plan: data.safetyPlanText,
        interaction_type: data?.interactionType ?? null,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data
  }

  public async saveCasenoteDraft(id: string, data: IHotlineCasenote): Promise<any> {
    const response = await this.apiService.post<any>(
      `backoffice/hotline/${id}/casenote`,
      {
        //TODO: Remove space when api ix fixed; space is default value
        lead_problem: data.leadProblem,
        general_info: data.generalInfo,
        problem_detail: data.problemDetail,
        help_method: data.helpMethod,
        result: data.result,
        refer_counselor_id: data.headShiftCounselorId,
        remark: data.remark,
        suicidal_assessment: data.suicidalAssessment,
        suicidal_idea: data.suicidalIdea === undefined ? null : data.suicidalIdea,
        suicidal_frequent: data.suicidalFrequent,
        suicidal_plan: data.suicidalPlan,
        suicidal_history_assessment: data.suicidalHistoryAssessment,
        suicidal_attempt: data.suicidalAttempt === undefined ? null : data.suicidalAttempt,
        selfharm_assessment: data.selfharmAssessment,
        selfharm_idea: data.selfharmIdea === undefined ? null : data.selfharmIdea,
        suicidal_plan_text: data.suicidalPlanText,
        safety_plan: data.safetyPlanText,
        interaction_type: data?.interactionType ?? null,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data
  }

  public async getCaseInfo(id: string): Promise<any> {
    const response = await this.apiService.get<any>(`backoffice/appointment/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data
  }

  public async submitTransferCasenote(id: string, data: IHotlineTransferCasenote): Promise<any> {
    const response = await this.apiService.post<any>(
      `backoffice/appointment/submitTransferCaseNote/${id}`,
      {
        transfer_status: data.transfer_status,
        consultType: data.consultType,
        adminnote: data?.adminnote ?? null,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data
  }

  public async getTransferCasenote(id: string | number): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/appointment/casenotetransferstatus/${id}`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data
  }

  public async sendFeedbackForm(hotlineId: string): Promise<void> {
    const response = await this.apiService.post<void>(
      `backoffice/hotline/${hotlineId}/feedback`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getCommentForm(id: string | number): Promise<any> {
    const response = await this.apiService.get<any>(`backoffice/hotline/${id}/comment`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data
  }

  public async sendComment(id: string, data: IHotlineComment): Promise<void> {
    const response = await this.apiService.post<void>(
      `backoffice/hotline/comment`,
      {
        appointment_id: id,
        text: data.text,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
  }

  public async getFolloUpStatus(id: string | number): Promise<any> {
    const response = await this.apiService.get<any>(`appointment/${id}/isfollowup`, {}, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }

    return response.data.data
  }

  public async setFollowUpStatus(id: string | number, isFollowUp: string): Promise<void> {
    const response = await this.apiService.post<void>(
      `appointment/${id}/isfollowup`,
      {
        isFollowUp: parseInt(isFollowUp),
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data
  }
}
